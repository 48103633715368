export default
`#### Other production costs (%)

_“Other operational costs”_ is equivalent to Other Production Costs.

These costs should comprise outsourcing costs, property or equipment rental charges,
the cost of raw materials and supplies that cannot be held in the inventory and have not been already specified.

Insurance premiums, studies and research costs, external personnel charges,
fees payable to intermediaries and professional expenses, advertising costs,
transportation charges, travel expenses, the costs of meetings and receptions,
postal charges, bank charges (but not interest on bank loans) and other items of expenditure.

- In %: Total annual other productions costs (€) divided by the total annual production cost. Then multiply the result by 100.`;